import React from 'react'
import { Link } from 'react-router-dom'

function ContactPage () {
  return (
    <div className='contactpage'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container-fluid'>
          <Link to='/' className='navbar-brand'>
            Secure Ballot Card
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/faq' className='nav-link'>
                  FAQs
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/contact' className='nav-link'>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className='container'>
        <h1>Contact Us</h1>
        <div className='row'>
          {/* <div className='col-md-6'>
            <form>
              <div className='mb-3'>
                <label htmlFor='name' className='form-label'>
                  Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='email' className='form-label'>
                  Email
                </label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='message' className='form-label'>
                  Message
                </label>
                <textarea
                  className='form-control'
                  id='message'
                  rows='5'
                  required
                />
              </div>
              <button type='submit' className='btn btn-primary'>
                Send Message
              </button>
            </form>
          </div> */}
          <div className='col-md-6'>
            {/* <h2>Visit Us</h2>
            <address>
              123 Main St<br />
              Anytown, USA 12345<br />
              <a href='tel:+1-555-555-5555'>+1 (555) 555-5555</a>
            </address> */}
            {/* <h2>Connect With Us</h2> */}
            <ul className='list-unstyled'>
            <li>
                <a href='mailto:tomand285@gmail.com'>
                  <i className='fab fa-facebook-square' />tomand285@gmail.com
                </a>
              </li>
              {/* <li>
                <a href='https://www.facebook.com/SecureBallotCard'>
                  <i className='fab fa-facebook-square' /> Facebook
                </a>
              </li>
              <li>
                <a href='https://www.twitter.com/SecureBallotCard'>
                  <i className='fab fa-twitter' /> Twitter
                </a>
              </li>
              <li>
                <a href='https://www.linkedin.com/company/SecureBallotCard'>
                  <i className='fab fa-linkedin' /> LinkedIn
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/SecureBallotCard'>
                  <i className='fab fa-instagram' /> Instagram
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactPage
