import React from 'react'
import { Link } from 'react-router-dom'

function HomePage () {
  return (
    <div className='homepage'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container-fluid'>
          <Link to='/' className='navbar-brand'>
            Secure Ballot Card
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item active'>
                <Link to='/' className='nav-link'>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/faq' className='nav-link'>
                  FAQs
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/contact' className='nav-link'>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className='hero-section'>
        <h1>Welcome to Secure Ballot Card</h1>
        <p>
          A Blockchain-based voting application.
        </p>
        {/* <button className='btn btn-primary'>Get Started</button> */}
      </div>
      <div className='container'>
        <h2>What We Offer</h2>
        <div className='row'>
          <div className='col-md-4'>
            {/* <img src='https://via.placeholder.com/300x200.png?text=Feature+1' alt='Immutability' /> */}
            <h3>Immutability</h3>
            <p>
            The immutability of the blockchain ensures that once data has been added to the blockchain, it cannot be altered or deleted. This is an essential feature for ensuring the security and integrity of the voting process.
            </p>
          </div>
          <div className='col-md-4'>
            {/* <img src='https://via.placeholder.com/300x200.png?text=Feature+2' alt='Decentralization' /> */}
            <h3>Decentralization</h3>
            <p>
            Decentralization is a key aspect of blockchain technology, which means that no single entity or party can control the entire network. This reduces the risk of malicious actors attempting to compromise the voting process and enhances the security and integrity of the system.
            </p>
          </div>
          <div className='col-md-4'>
            {/* <img src='https://via.placeholder.com/300x200.png?text=Feature+3' alt='Reduced Costs' /> */}
            <h3>Reduced Costs</h3>
            <p>
            Secure Ballot Card can lead to faster results with no need for a recount, potentially reducing administrative costs associated with traditional voting systems. This is due to the automated vote counting and verification processes enabled by the blockchain, which can reduce the need for manual vote counting and associated administrative tasks.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
