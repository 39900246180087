import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function FaqPage () {
  const [faqs] = useState([
    {
      id: 1,
      question: 'What is a blockchain-based voting application?',
      answer: 'A blockchain-based voting application is a digital voting platform that leverages the security and transparency of blockchain technology to ensure secure and transparent voting processes.'
    },
    {
      id: 2,
      question: 'How does the Ethereum blockchain work in a voting application?',
      answer: 'The Ethereum blockchain provides a decentralized platform that enables secure and transparent transactions. In a voting application, it is used to record and validate votes, ensuring that they are immutable and tamper-proof.'
    },
    {
      id: 3,
      question: 'What are the advantages of using a blockchain-based voting application?',
      answer: 'Blockchain-based voting applications provide transparency, security, and accessibility, making voting more efficient and trustworthy. The immutable nature of the blockchain ensures that votes cannot be altered or deleted, reducing the risk of fraud. It also has the added benifit of being cheaper then tranditional voting as no one is required to manually count the vote.'
    },
    {
      id: 4,
      question: 'How does the voting process work in a blockchain-based voting application?',
      answer: 'Voters are issued a unique digital identity card that they use to cast their vote and verify that they have only voted once. When they approach the voting booth, they insert their card to confirm that they have not yet voted. After that, the voting process proceeds as usual. Voters can select their preferred candidate and submit their vote to the blockchain. Once the vote is recorded on the blockchain, it becomes immutable and tamper-proof, ensuring the integrity of the voting process.'
    },
    {
      id: 5,
      question: 'Can the votes be traced back to the voter in a blockchain-based voting application?',
      answer: "No, the voting process ensures anonymity by encrypting the voter's identity, making it impossible to trace back to the vote. The identity card does not contain any personally identifiable information but instead features a unique, cryptographically secure number assigned exclusively to the holder. This guarantees the voter's privacy and prevents any attempts to tie their identity to their vote."
    },
    {
      id: 6,
      question: 'How does the blockchain-based voting application ensure the integrity of the vote?',
      answer: 'The blockchain-based voting application uses cryptographic algorithms to validate and authenticate each vote. The use of smart contracts also ensures that the voting process is transparent and tamper-proof.'
    },
    {
      id: 7,
      question: 'What happens if there is a dispute in the voting process?',
      answer: 'The blockchain-based voting application provides a transparent and auditable record of all votes. In case of any dispute, the platform can be used to validate and resolve the issue.'
    },
    {
      id: 8,
      question: 'How is voter eligibility verified in a blockchain-based voting application?',
      answer: "Valid voters go through an automated process which provides a unique identification card. The platform uses this information to verify the voter's eligibility and to check if they have voted before or if the provided identification is valid."
    },
    {
      id: 9,
      question: 'How does the blockchain-based voting application prevent double voting?',
      answer: "The platform uses unique digital identities to ensure that each voter can only cast one vote. The blockchain's immutability also ensures that votes cannot be altered or deleted."
    },
    {
      id: 10,
      question: 'Can the blockchain-based voting application be hacked?',
      answer: 'The blockchain-based voting application uses advanced security protocols and encryption to prevent hacking attempts. The decentralized nature of the platform also makes it difficult for hackers to target a single point of failure.'
    },
    {
      id: 11,
      question: 'How does the blockchain-based voting application ensure the confidentiality of the vote?',
      answer: 'The confidentiality of the vote is ensured through the use of encryption and anonymous digital identities. Only authorized personnel can access the encrypted data, ensuring that the votes remain confidential.'
    },
    {
      id: 12,
      question: 'Can the results of the election be audited after the voting process has ended?',
      answer: 'Yes, the blockchain-based voting application provides a transparent and auditable record of all votes, which can be accessed for auditing and validation purposes. The votes remain encrypted during the election process, and after the vote, a private key becomes available to independent verifiers to confirm the results. This feature ensures that the voting process is transparent and the results are trustworthy.'
    },
    {
      id: 13,
      question: 'Is the blockchain-based voting application compatible with traditional voting systems?',
      answer: 'Yes, the blockchain-based voting application will be integrated with traditional voting systems to provide a more secure and transparent voting process.'
    },
    {
      id: 14,
      question: 'How are new voters added to the blockchain-based voting application?',
      answer: 'New voters can join the platform through the same traditional voting registration process that is currently in place. After completing the registration process, a unique digital identity card will be mailed to the new voter, which they can use to participate in the blockchain-based voting application.'
    },
    {
      id: 15,
      question: 'What happens if a voter loses their unique voting identification card?',
      answer: 'To request a new digital identity card, the voter must visit the voting commission and verify their identity, eligibility to vote, and that they have indeed lost their card. Once verified, the voting commission will revoke the old digital identity card and issue a new one to the voter. This process ensures that the voting process remains secure and that each voter has only one unique digital identity card at any given time.'
    },
    {
      id: 16,
      question: 'How does the blockchain-based voting application handle voter turnout?',
      answer: 'The platform can handle large volumes of voters through its decentralized architecture. The use of smart contracts also ensures that the voting process is efficient and streamlined. There will also be a team monitoring the election at all times to ensure the process goes smoothly.'
    },
    {
      id: 17,
      question: 'Can voters change their vote after submitting it to the blockchain?',
      answer: 'No, once a vote has been submitted to the blockchain, it cannot be changed or deleted. This ensures that the integrity of the voting process is maintained.'
    },
    {
      id: 18,
      question: 'How are the results of the election displayed after the election?',
      answer: 'To maintain the integrity of the voting process, the election results will remain encrypted and inaccessible until the end of the voting period. Once the election is complete, the public key will be released, allowing independent users to verify the results. Additionally, users will be able to view the election results on this website. This ensures that the voting process is transparent and trustworthy for all parties involved.'
    },
    {
      id: 19,
      question: 'Can the blockchain-based voting application be used for all types of elections?',
      answer: 'Yes, the blockchain-based voting application can be used for a wide range of elections, including local, national, and international elections.'
    },
    {
      id: 20,
      question: 'How is the security of the blockchain-based voting application maintained over time?',
      answer: 'The security of the blockchain-based voting application is maintained through continuous monitoring and updates to security protocols. The platform can also be audited regularly to ensure that it remains secure and transparent.'
    },
    {
        id: 21,
        question: 'When will the Secure Ballot Card be implemented?',
        answer: 'We are in the process of developing and testing a prototype to ensure that the implementation of the blockchain-based voting application is secure and reliable. We are taking extensive measures to identify and address any potential vulnerabilities in the product. A testable demo will be available by the end of April, allowing users to test the application and provide valuable feedback.'
    },
    {
        id: 22,
        question: 'Whom should I contact if I have an issue?',
        answer: 'For inquiries, please reach out to Andrew Tomko at tomand285@gmail.com. As we approach the production phase, we will establish a dedicated support hotline to assist users with any questions or issues they may encounter while using the blockchain-based voting application.'
    },
    {
        id: 23,
        question: 'How do I validate the results myself?',
        answer: 'Following the election, you will be able to download our software to validate the results. Since the election results are stored on the blockchain, anyone can validate them after receiving the public key. We are currently working on providing detailed instructions for users to validate the election results independently. These instructions will include steps for using our software as well as creating your own validation process. This will enable users to verify the results in a way that suits their needs and preferences, ensuring that the voting process is transparent and trustworthy for all parties involved.'
    }
  ])

  return (
    <div className='faq-page'>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <div className='container-fluid'>
          <Link to='/' className='navbar-brand'>
            Secure Ballot Card
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/faq' className='nav-link active'>
                  FAQs
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/contact' className='nav-link'>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className='container'>
        <h1 className='faq-page-title'>FAQs</h1>
        <div className='accordion'>
          {faqs.map((faq) => (
            <div className='accordion-item' key={faq.id}>
              <h2 className='accordion-header'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#collapse-${faq.id}`}
                  aria-expanded='false'
                  aria-controls={`collapse-${faq.id}`}
                >
                  {faq.question}
                </button>
              </h2>
              <div
                id={`collapse-${faq.id}`}
                className='accordion-collapse collapse'
                aria-labelledby={`heading-${faq.id}`}
                data-bs-parent='.accordion'
              >
                <div className='accordion-body'>{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FaqPage
