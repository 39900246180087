import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import FaqPage from './FaqPage'
import ContactPage from './ContactPage'

import './App.css'

function App () {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='/contact' element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
